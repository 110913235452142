<template>
    <modal ref="modalVerificarSaldo" titulo="Verificación de saldo" no-aceptar adicional="Verificar" @adicional="crearVericacionCaja">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center">
                <div class="col-8 text-general">
                    <b class="f-26">
                        {{ separadorNumero(saldo) }}
                    </b>
                    <small>Saldo actual</small>
                </div>
                <div class="col-10 mt-3">
                    <ValidationProvider v-slot="{errors}" :rules="`required|max_value:999999999.99`" name="ingreso" class="d-flex flex-column">
                        <p class="text-general pl-3 f-12">Valor del saldo</p>
                        <div class="d-middle border br-6 w-100" style="height:38px;">
                            <input-miles-moneda v-model="model.valor_real" :id-moneda="moneda" placeholder="Valor del saldo" border maxlength="13" />
                            <div class="py-1 d-middle-center px-1 mr-1 h-100">
                                {{ sigla }}
                            </div>
                        </div>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-10 mt-3">
                    <ValidationProvider v-slot="{errors}" rules="required" name="comentario">
                        <p class="text-general pl-3 f-14">Comentario</p>
                        <el-input v-model="model.comentario" type="textarea" :rows="5" placeholder="Deja un comentario" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Cajas from "~/services/flujoDinero/cajas";
export default {
    data(){
        return {
            model: {
                id_caja: null,
                valor_real: null,
                valor_teorico: null,
                id_moneda: null,
                comentario: '',
            },
            sigla: 'COP',
            saldo: null,
            moneda: null,
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    methods: {
        toggle({ id_caja, sigla, moneda, saldo}){
            this.model.id_caja = id_caja
            this.model.id_moneda = moneda
            this.model.valor_real = saldo
            this.model.valor_teorico = saldo
            this.sigla = sigla
            this.moneda = moneda
            this.saldo = saldo
            this.$refs.modalVerificarSaldo.toggle();
        },
        async crearVericacionCaja(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.model.idm_moneda = this.id_moneda

                const {data} = await Cajas.crearVericacionCaja(this.model)
                this.notificacion('', data.message, 'success')
                this.$emit('ingreso', { saldo: data.saldo, caja: data.caja})
                this.limpiar()
                this.$refs.modalVerificarSaldo.toggle();
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model = {
                id_caja: null,
                valor_real: null,
                valor_teorico: null,
                id_moneda: null,
                comentario: '',
            }
        }
    }
}
</script>
